::-webkit-scrollbar {
	width: 8px;
	height: 10px;
}

@media only screen and (max-width: 500px) {
	::-webkit-scrollbar {
		width: 0px;
		height: 0px;
	}
}

/* Track */
::-webkit-scrollbar-track {
	-webkit-border-radius: 0px;
	border-radius: 0px;
}

/* Handle */
::-webkit-scrollbar-thumb {
	-webkit-border-radius: 0px;
	border-radius: 0px;
	background: rgba(29, 161, 242, 0.5);
}

::-webkit-scrollbar-thumb:window-inactive {
	background: rgba(29, 161, 242, 0.5);
}