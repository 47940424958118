.viewerContainer {
    margin: auto;
    position: relative;
    border: solid 3px rgba(88, 88, 88, 0.234);
    border-radius: 1.2rem;
    margin-bottom: 105px;
}

.viewer {
    background-color: rgba(222, 222, 222, 0.9);
    border-radius: 1rem;
}

.viewerContainer:hover {
    border-color: rgba(169, 169, 169, 0.866);
}

#proxy-renderer {
    -ms-overflow-style: none;
    scrollbar-width: none;
    overflow-y: auto;
} 

#react-doc-viewer {
    height: calc(100vh - 200px);
    width: 100%;
}