.switch-button {
    background: rgba(33, 150, 243, 0.3);
    border-radius: 30px;
    overflow: hidden;
    width: 230px;
    text-align: center;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 1px;
    color: #ffffff;
    position: relative;
    padding-right: 120px;
    position: relative;
}

.switch-button:before {
    content: "Sign Up";
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    width: 109px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 3;
    pointer-events: none;
}

.switch-button-checkbox {
    cursor: pointer;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: 2;
}

.switch-button-checkbox:checked+.switch-button-label:before {
    transform: translateX(120px);
    transition: transform 300ms linear;
}

.switch-button-checkbox+.switch-button-label {
    position: relative;
    padding: 5px 0;
    display: block;
    user-select: none;
    pointer-events: none;
}

.switch-button-checkbox+.switch-button-label:before {
    content: "";
    background: #2196f3;
    height: 100%;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    border-radius: 30px;
    transform: translateX(0);
    transition: transform 300ms;
}

.switch-button-checkbox+.switch-button-label .switch-button-label-span {
    position: relative;
}