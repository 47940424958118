.bgColor1 {
    background-color: #2196f3;
    width: 148px;
    height: 38px;
    position: absolute;
    left: 0px;
    top: 0px;
    transition: left 0.2s linear;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}

.bgColor2 {
    background-color: #2196f3;
    width: 148px;
    height: 38px;
    position: absolute;
    left: 151px;
    top: 0px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    transition: left 0.2s linear;
}

.bgExisitingColor {
    background-color: #2196f3;
    width: 224px;
    height: 34px;
    position: absolute;
    left: 0px;
    top: 0px;
    transition: all 0.2s linear;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
}

.bgImportedColor {
    background-color: #2196f3;
    width: 224px;
    height: 34px;
    position: absolute;
    left: 225px;
    top: 0px;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    transition: all 0.2s linear;
}