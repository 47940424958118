.carousel {
  display: flex;
  animation: carouselAnimation 20s linear infinite;
}

.carousel1 {
  display: flex;
  animation: carouselAnimation1 20s linear infinite;
}

@keyframes carouselAnimation {
  0% {
    transform: translateX(0%);
  }

  25% {
    transform: translateX(-80%);
  }

  50% {
    transform: translateX(0%);
  }

  75% {
    transform: translateX(80%);
  }

  100% {
    transform: translateX(0%);
  }
}

@keyframes carouselAnimation1 {
  0% {
    transform: translateX(0%);
  }

  25% {
    transform: translateX(80%);
  }

  50% {
    transform: translateX(0%);
  }

  75% {
    transform: translateX(-80%);
  }

  100% {
    transform: translateX(0%);
  }
}