.actionbar {
  display: flex;
  flex-direction: column;
  bottom: -140px;
  position: absolute;
  align-self: center;
  border: 2px solid black;
  width: 100%;
  height: 6rem;
  justify-content: center;
  margin-bottom: 2rem;
  border-radius: 1rem;
  background-color: rgb(73, 73, 73);
  color: white;
}
.title {
  display: flex;
  justify-content: center;
  font-weight: 600;
  margin: 0.4rem 1rem;
  font-size: small;
  overflow-wrap: break-word;
  text-align: left;
}
.index {
  font-size: small;
  display: flex;
  margin: 0 0.5rem;
  flex-grow: 1;
  flex-direction: row-reverse;
}
.prev {
  float: left;
}
.next {
  float: right;
}
.prev,
.next {
  padding: 0.3rem 3rem;
  margin: 1rem;
  font-weight: 600;
  border-radius: 1rem;
  background-color: rgb(116, 116, 116);
  color: rgb(0, 0, 0);
  font-size: small;
  border: solid 1px rgba(161, 161, 161, 0.7);
}
.prev:hover,
.next:hover {
  background-color: rgb(43, 43, 43);
  color: rgb(168, 168, 168);
  border: solid 1px rgba(161, 161, 161, 0.582);
  cursor: pointer;
}
.prev:disabled,
.next:disabled {
  background-color: rgba(116, 116, 116, 0.2);
  color: rgba(0, 0, 0, 0.2);
  cursor: default;
  border: solid 1px rgba(161, 161, 161, 0.2);
}
