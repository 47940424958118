.EditorStyle1 {
    width: 100%;
}

.EditorStyle1 .ql-editor {
    padding: 12px 20px;
    height: 40vh;
}

.EditorStyle1 .ql-container {
    border-radius: 0 0 5px 5px;
    font-size: .9rem;
    margin-top: -12px;
    border: 1px solid #dedddd;
}

.EditorStyle1 .ql-toolbar {
    border-radius: 5px;
    background-color: white;
    margin-bottom: 10px;
}

.EditorStyle {
    width: 100%;
}

.EditorStyle .ql-editor {
    padding: 12px 20px;
    height: 50vh;
}

.EditorStyle .ql-container {
    border-radius: 0 0 5px 5px;
    font-size: .9rem;
    margin-top: 60px;
    border: none;
}

.EditorStyle .ql-toolbar {
    border-radius: 5px;
    background-color: white;
    margin-bottom: 10px;
}

.titleDivCont {
    border: 1px solid #CCCCCC;
    border-bottom: none;
    position: relative;
    top: 102px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    padding: 0px 10px;
}

@media only screen and (max-width: 1011px) {
    .titleDivCont {
        top: 130px;
    }
}

@media only screen and (max-width: 500px) {
    .titleDivCont {
        top: 150px;
    } 
}

@media only screen and (max-width: 350px) {
    .titleDivCont {
        top: 175px;
    }
}

@media only screen and (max-width: 322px) {
    .titleDivCont {
        top: 200px;
    }
}