.photoCard {
    width: 230px;
    height: 280px;
    border-radius: 3px;
    box-shadow: 0 1px 4px rgba(0, 0, 0, .3);
    overflow: hidden;
}

.photoCard .thumb {
    width: auto;
    height: 225px;
    border-radius: 3px;
    background-color: rgba(46, 115, 248, 0.1);
    background-size: cover;
    background-position: center;
    background-blend-mode: multiply;
    color: #fff;
}

.photoCard .infos {
    width: auto;
    height: 280px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: relative;
    padding: 6px 11px;
    background: #fff;
    transition: 0.4s 0.15s cubic-bezier(0.17, 0.67, 0.5, 1.03);
}

.photoCard .infos .title {
    position: relative;
    margin: 0px;
    letter-spacing: 1px;
    color: #152536;
    font-size: 17px;
    font-weight: 510;
    text-transform: capitalize;
}

.photoCard .infos .txt {
    font-family: 'Merriweather', sans-serif;
    font-size: 12px;
    word-break: break-all;
    margin-top: 5px;
    color: rgba(21, 37, 54, .7);
    opacity: 0;
    transition: 0.5s 0.25s cubic-bezier(0.17, 0.67, 0.5, 1.03);
}

.photoCard:hover .infos {
    transform: translateY(-180px);
}

.photoCard:hover .infos .txt {
    opacity: 1;
}